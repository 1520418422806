.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.requird-span {
  color: red;
  margin-right: 5px;
}

.home {
  height: calc(100% - 45px);
}

.head {
  height: 45px;
  line-height: 45px;
  background-color: #EEEEEE;
}

.head-set, .head-clear {
  width: 20px;
  height: 45px;
  display: flex;
  align-items: center;
}

.head-set img, .head-clear img{
  width: 100%;
  cursor: pointer;
}

.head {
  width: 100%;
  /* border: 1px solid pink; */
  border-bottom: 1px solid var(--gray-5);
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.head-title {
  color: var(--gray-1);
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
  text-align: center;
}

label span {
  line-height: 25px;
  margin-left: 5px;
}

/* 弹出层 */
.pop-outer {
  padding: 0 20px;
}
.zhihu-list {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 20px;
}
.zhihu-item {
  padding: 10px 20px;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.zhihu-item:last-child {
  border-bottom: none;
}

.zhihu-item:active {
  color: #666;
}

.zhihu-item >div {
  width: 92%;
}

.search-icon {
  color: #999;
  font-size: 25px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
